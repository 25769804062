.popup{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.popupCenter{
    color: #fff;
    background: rgba(0,0,0,.5);
    border-radius: 20px;
    font-size: 22px;
    line-height: 30px;
    padding: 10px 20px;
}
