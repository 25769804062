.bg{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 999;
    background: rgba(0,0,0,.6);
}

.imgCenter{
    width: 80%;
    height: auto;
    margin-top: 12px;
    margin-left: 10%;
    margin-right: 10%;
}
