body, html {
  height: 100%;
  margin: 0;
}

.contain {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
}
.contentView {
  box-sizing: border-box;
}

.mainTopImg {
  width: 100%;
  height: auto;
  display:block;
}

.mainVideoDiv {
  width: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-repeat:no-repeat;
  background-size:100% 100%;

}

.mainVideoInner {
  width: 270px;
  height: 480px;
  border: 2px solid #ff713e;
  border-radius: 6px;
}

.download-button-float {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 30%;
  left: 0;
  right: 0;
  align-items: center;
}

.banTopCpDiv {
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.bottomBannerDiv {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}
.bottomBannerLeftDiv {
  height: 65px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;
}
.bottomBannerLogo {
  width: 46px;
  height: 46px;
  border-radius: 6px;
}
.bottomBannerTitleDiv {
  flex: 1;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: 10px;
}
.bottomBannerTitleP1 {
  font-size: 16px;
  color: white;
}
.bottomBannerTitleP2 {
  font-size: 10px;
  color: white;
}
.bottomBannerTextDiv {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: #fe793c;
  font-size: 14px;
  border-radius: 18px;
  padding: 7px 22px;
}
.topBtnImg {
  width: 100%;
  height: auto;
  display:block;
}

.banner2 {
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}

.videos {
  width: 100%;
  padding-bottom: 10px;
}

.appOpenDiv {
  width: 100%;
  margin-top: 10px;
}

.moreInfoDiv {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 10px;
  background: rgba(255,255,255,1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.toAppImg{
  width: 60%;
  height: auto;
}

.scTop{
  width: 100%;
  height: auto;
}
.moreInfoText {
  padding: 10px;
  font-size: 13px;
  color: #C1C1C1;
}

.download {
  margin-top: 10px;
  margin-bottom: 0px;
}

.download_desc {
  width: 100%;
  margin: 0px;
}

.topDiv{
  animation: position_move 3s ease-out 1;
  width: 100%;
  position: fixed;
  bottom: 110px;
  z-index: 997;
  height: 95px;
  display:flex;
  align-items:center;
  justify-content: center;
  flex-direction: row;
}

.closeDiv{
  position: absolute;
  z-index: 99;
  top: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topLogo{
  width: 100%;
  height: 100%;
}

.closeImg{
  width: 13px;
  height: 14px;
}

@keyframes position_move {
  from{
    width: 100%;
    position: fixed;
    bottom: 100%;
    z-index: 997;
  }
  to{
    width: 100%;
    position: fixed;
    bottom: 110px;
    z-index: 997;
  }
}
@-moz-keyframes position_move {
  from{
    width: 100%;
    position: fixed;
    bottom: 100%;
    z-index: 997;
  }
  to{
    width: 100%;
    position: fixed;
    bottom: 110px;
    z-index: 997;
  }
}
@-webkit-keyframes position_move {
  from{
    width: 100%;
    position: fixed;
    bottom: 100%;
    z-index: 997;
  }
  to{
    width: 100%;
    position: fixed;
    bottom: 110px;
    z-index: 997;
  }
}
@-o-keyframes position_move{
  from{
    width: 100%;
    position: fixed;
    bottom: 100%;
    z-index: 997;
  }
  to{
    width: 100%;
    position: fixed;
    bottom: 110px;
    z-index: 997;
  }
}

.iosDownloadDialogDiv {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.androidTipDialog {
  width: 80%;
  padding-bottom: 20px;
  z-index: 100;
  background-color: #161823;
  border-radius: 10px;
}

.androidBottomDiv {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.androidTextDesc {
  margin-top: 20px;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}

.androidTextDesc p{
  padding: 0;
  margin: 6px 0 0;
  text-align: left;
}

.textTipStyle {
  color: #00c1ff;
}

.androidButtonDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}


.androidButton {
  width: 141px;
  height: 40px;
  background-color: #383A44;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.viewImg {
  width: 100%;
}
